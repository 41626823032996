import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import {WithMenu} from "../shared/WithMenu";
import {Home} from "../pages/private/Home";
import {SubFolders} from "../pages/private/SubFolders";
import {ViewPDF} from "../pages/private/ViewPDF";
import {Users} from "../pages/private/Users";
import {CreateUserForm} from "../pages/private/Users/Forms/Create";
import {Login} from "../pages/public/Authentication/Login";
import {ProtectedRoute} from "../shared/ProtectedRoute";
import {UpdateUserForm} from "../pages/private/Users/Forms/Update";
import {UpdatePasswordUserForm} from "../pages/private/Users/Forms/UpdatePassword";

export const Routers = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/">
                <Route index element={<ProtectedRoute component={<WithMenu page={<Home/>}/>}/>} />
                <Route path="login" element={<Login/>} />

                <Route path="/folder/:folderId" element={<ProtectedRoute component={<WithMenu page={<SubFolders/>}/>}/>}  />
                <Route path="/file/:fileId" element={<ProtectedRoute component={<WithMenu page={<ViewPDF/>}/>}/>}  />
                <Route path="users">
                    <Route index element={<ProtectedRoute requireAdmin component={<WithMenu page={<Users/>}/>}/>}  />
                    <Route path="create" element={<ProtectedRoute requireAdmin component={<WithMenu page={<CreateUserForm/>}/>}/>} />
                    <Route path="update/:userId" element={<ProtectedRoute requireAdmin component={<WithMenu page={<UpdateUserForm/>}/>}/>} />
                    <Route path="update/password" element={<ProtectedRoute component={<WithMenu page={<UpdatePasswordUserForm/>}/>}/>} />
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>
)