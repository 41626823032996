import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import { Button, Col, Container, Row } from "react-bootstrap";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { fileService } from "../../../services/fileService";
import { getToken, userIsAdmin } from "../../../services/auth";
import { Icon, IconWidget } from "../../../components/Icons";
import {RouteBackButton} from "../../../components/RouteBackButton";

export const ViewPDF = () => {
  const navigate = useNavigate();
  let { fileId } = useParams();
  const [file, setFile] = useState({});
  const token = getToken();
  function fetchFile() {
    fileService.open({ fileId }).then((response) => {
      setFile(response.data);
    });
  }

  function handleDelete() {
    if (
      window.confirm("Tem certeza que deseja deletar este arquivo?") !== true
    ) {
      return;
    }

    fileService.delete({ fileId }).then(() => {
      alert("Arquivo deletado");
      navigate("/");
    });
  }

  useEffect(fetchFile, [fileId]);

  return (
    <>
      <Container>
        <Row className={"py-4"}>
            <Col>
              <h1>{file.name}</h1>
              <RouteBackButton/>{" "}
              { userIsAdmin() && (<Button
                  variant={"danger"}
                  onClick={handleDelete}
                  title="Deletar Pasta"
              >
                <IconWidget icon={Icon.DeleteWhite} alt="delete folder icon"/>
              </Button>)}
            </Col>
          </Row>
      </Container>
      <Container fluid style={{padding: 0}}>
        <Row >
          <Col md={12}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.js">
              <div style={{ height: "100%" }}>
                <Viewer
                    theme={"dark"}
                    httpHeaders={{
                      authorization: `Bearer ${token}`,
                    }}
                    fileUrl={fileService.openStream({ fileId })}
                    plugins={[defaultLayoutPlugin()]}
                />
              </div>
            </Worker>
          </Col>
        </Row>
      </Container>
    </>
  );
};
