import styled from "styled-components"
import {Link} from "react-router-dom";
export const File = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px solid transparent;
  border-radius: 8px;

  text-decoration: none;
  color: #151515;
  
  &:hover {
    color: #151515;
    border: 4px solid rgba(171, 171, 171, 0.6);
    background-color: rgba(224, 224, 224, 0.6);
    cursor: pointer;
  }
`

export const Title = styled.span`
  text-align: center;
  display: inline-flex;
`
