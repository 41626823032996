import AddFolder from "../../assets/icons/addfolder.svg";
import AddFolderWhite from "../../assets/icons/addfolderwhite.svg";
import AddUser from "../../assets/icons/adduser.svg";
import AddUserWhite from "../../assets/icons/adduserwhite.svg";
import File from "../../assets/icons/file.svg";
import FileWhite from "../../assets/icons/filewhite.svg";
import FileBox from "../../assets/icons/filebox.svg";
import FileBoxWhite from "../../assets/icons/fileboxwhite.svg";
import Folder from "../../assets/icons/folder.svg";
import FolderWhite from "../../assets/icons/folderwhite.svg";
import Settings from "../../assets/icons/settings.svg";
import SettingsWhite from "../../assets/icons/settingswhite.svg";
import Upload from "../../assets/icons/upload.svg";
import UploadWhite from "../../assets/icons/uploadwhite.svg";
import Users from "../../assets/icons/users.svg";
import UsersWhite from "../../assets/icons/userswhite.svg";
import Edit from "../../assets/icons/edit.svg";
import EditWhite from "../../assets/icons/editwhite.svg";
import Save from "../../assets/icons/save.svg";
import SaveWhite from "../../assets/icons/savewhite.svg";
import Delete from "../../assets/icons/trash.svg";
import DeleteWhite from "../../assets/icons/trashwhite.svg";
import Logout from "../../assets/icons/logout.svg";
import LogoutWhite from "../../assets/icons/logoutwhite.svg";
import LogoutRed from "../../assets/icons/logoutred.svg";
import ArrowLeft from "../../assets/icons/arrowLeft.svg";

import { ImageIcon } from "./styles";

export const Icon = {
  AddFolder,
  AddFolderWhite,
  AddUser,
  AddUserWhite,
  Edit,
  EditWhite,
  File,
  FileWhite,
  FileBox,
  FileBoxWhite,
  Folder,
  FolderWhite,
  Save,
  SaveWhite,
  Settings,
  SettingsWhite,
  Delete,
  DeleteWhite,
  Upload,
  UploadWhite,
  Users,
  UsersWhite,
  Logout,
  LogoutWhite,
  LogoutRed,
  ArrowLeft
};

export const IconWidget = ({ icon, alt }) => {
  return <ImageIcon src={icon} alt={alt} />;
};
