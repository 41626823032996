import serviceUrl from "../config/baseUrls";
import baseApi from "./api";

const env = serviceUrl.API_LOCAL// process.env.NODE_ENV === 'PROD' ? serviceUrl.API_LOCAL : serviceUrl.API_LOCAL
const api = baseApi(env)

export const userService = {
    list: () => {
        return api.get('/users')
    },
    show: ({userId}) => {
        return api.get(`/users/${userId}`)
    },
    create: ({name, email, password = '123456789' , isAdmin, isEnabled = true}) => {
        return api.post('/users',
            {name, email, password, isAdmin, isEnabled},
        )
    },
    update: (userId, formData) => {
        return api.patch(`/users/${userId}`,
            {...formData},
        )
    },
    updatePassword: ({password, newPassword}) => {
        return api.patch(`users/me/password`, {password, newPassword},
        )
    },
    delete: ({userId}) => {
        return api.delete(`/users/${userId}`)
    },
}
