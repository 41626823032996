import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import {Icon, IconWidget} from "../Icons";

export function RouteBackButton(){
    const navigate = useNavigate()
    return (
        <Button
            variant="outline-dark"
            onClick={() => navigate(-1)}
            title="Voltar"
        >
            <IconWidget icon={Icon.ArrowLeft} alt="Voltar" />{" "}
            Voltar
        </Button>
    )
}