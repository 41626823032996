import {useState} from "react";
import { useNavigate } from 'react-router-dom'
import {Button, Col, Container, Form, Row, Alert, Spinner} from "react-bootstrap";
import {AppVersion, BoxContent, BoxForm} from "./style";
import {accountService} from "../../../../services/accountsService";
import {isAuthenticated, loginToken} from "../../../../services/auth";
import { Logo } from '../../../../components/Logo'

export const Login = () => {
    const navigate = useNavigate();

    const [authForm, setAuthForm] = useState({
        email: "",
        password: ""
    })
    const [formError, setFormError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    function handleChangeForm(event, field){
        setAuthForm({...authForm, [field]: event.target.value})
    }

    function handleLogin(event){
        event.preventDefault();
        if(!authForm.email && !authForm.password){
            setFormError('Preencha todos os campos corretamente')
            return
        }
        setIsLoading(true)
        accountService
            .login(authForm)
            .then((response) => {
                const {accessToken, user: { name }} = response.data
                loginToken(accessToken, name)
            })
            .catch((error) => {
                const { message } = error.response.data
                setFormError(message)
                if(error.response.status === 404){
                    setFormError('User not found')
                }
            })
            .finally(()=> {
                setIsLoading(false)
                if(isAuthenticated()){
                    navigate('/')
                }
            })

    }
    return (
        <>
            <Container fluid>
                <Row className="justify-content-md-center align-items-center vh-100" >
                    <Col sm={12} md={6} lg={"4"}>
                        <BoxContent>
                            <Logo/>
                        </BoxContent>
                        <BoxForm>
                            {formError && <Alert variant={"danger"}>{formError}</Alert>}

                            <Form onSubmit={handleLogin}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" value={authForm.email} onChange={e => handleChangeForm(e, 'email')} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" value={authForm.password} onChange={e => handleChangeForm(e, 'password')} />
                                </Form.Group>

                                <div className="d-grid gap-2">
                                    <Button variant="secondary" type="submit" disabled={isLoading}>
                                        { isLoading ? <Spinner animation={'border'}/> : 'Acessar'}
                                    </Button>
                                </div>
                            </Form>
                        </BoxForm>
                        <BoxContent>
                        <AppVersion>PSA Library - AgroGB</AppVersion>

                        </BoxContent>
                    </Col>
                </Row>
            </Container>
        </>
    )
}