import FileIcon from "../../assets/icons/file.svg"

import {Title, Folder, FolderList} from "./style"

import Diagnostico from '../../assets/icons-category/files/diagnostico.svg'
import Estrategia from '../../assets/icons-category/files/estrategia.svg'
import Matriz from '../../assets/icons-category/files/matriz.svg'
import Organizacao from '../../assets/icons-category/files/organizacao.svg'
import Projeto from '../../assets/icons-category/files/projeto.svg'
import Protocolo from '../../assets/icons-category/files/protocolo.svg'
import Sociedade from '../../assets/icons-category/files/sociedade.svg'

const fileAssetIcons = {
    'default': FileIcon,
    'diagnostico': Diagnostico,
    'projeto': Projeto,
    'estrategia': Estrategia,
    'organizacao': Organizacao,
    'matriz': Matriz,
    'protocolo': Protocolo,
    'sociedade': Sociedade,
}

export const FileButton = ({iconName, isList = true, title, path}) => {
    const titleParser = title.length >= 20
    ? `${title.substring(0, 20)}...`
    : title

    if(isList){
        return (
            <FolderList to={path} title={title}>
                <img src={fileAssetIcons[iconName]} alt="folder"/>
                <Title>{title}</Title>

            </FolderList>
        )
    }

    return (
        <Folder to={path} title={title}>
            <img src={fileAssetIcons[iconName]} alt="folder"/>
            <Title>{titleParser}</Title>
        </Folder>
    )
}