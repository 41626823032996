import jwt from 'jwt-decode'

export const TOKEN_KEY = '@psa-library/token'
export const USER_NAME_KEY = '@psa-library/userName'

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const getUserName = () => localStorage.getItem(USER_NAME_KEY)

export const isAuthenticated = () => getToken() !== null

export const tokenInfo = () => {
    const token = getToken()
    const {userId, isAdmin, isEnabled} = jwt(token);
    return {userId, isAdmin, isEnabled}
}

export const userIsEnabled = () => isAuthenticated() ? tokenInfo().isEnabled : false
export const userIsAdmin = () => isAuthenticated() ? tokenInfo().isAdmin : false

export function loginToken(token, userName){
    localStorage.setItem(TOKEN_KEY, token)
    localStorage.setItem(USER_NAME_KEY, userName)
}

export function logoutToken(){
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER_NAME_KEY)
}