import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { FolderButton } from "../../../components/FolderButton";
import { FileButton } from "../../../components/FileButton";
import { folderService } from "../../../services/folderService";
import { fileService } from "../../../services/fileService";
import { userIsAdmin } from "../../../services/auth";
import { Icon, IconWidget } from "../../../components/Icons";

import { CreateFolderForm } from "./Forms/CreateFolder";
import { CreateFileForm } from "./Forms/CreateFile";

export const Home = () => {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);

  const [openFolderForm, setOpenFolderForm] = useState(false);
  const [openFileForm, setOpenFileForm] = useState(false);

  function handleCloseForm() {
    setOpenFolderForm(false);
    setOpenFileForm(false);
  }

  function fetchFolders() {
    folderService.list().then((response) => {
      setFolders(response.data);
    });
  }

  function fetchFiles() {
    fileService.list().then((response) => {
      setFiles(response.data);
    });
  }

  useEffect(() => {
    fetchFolders();
    fetchFiles();
  }, []);

  return (
    <Container>
      {userIsAdmin() && (
        <>
          <Row className={"my-4"}>
            <Col>
              <h1>Diretório inicial</h1>
              {!openFolderForm && !openFileForm && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => setOpenFolderForm(true)}
                  >
                    <IconWidget
                      icon={Icon.AddFolderWhite}
                      alt="add folder icon"
                    />
                    Nova Pasta
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setOpenFileForm(true)}
                  >
                    <IconWidget icon={Icon.UploadWhite} alt="add file icon" />
                    Novo Arquivo
                  </Button>
                </>
              )}

            </Col>
          </Row>
          {openFolderForm && (
            <CreateFolderForm
              closeForm={handleCloseForm}
              reFetch={fetchFolders}
            />
          )}

          {openFileForm && (
            <CreateFileForm
              closeForm={handleCloseForm}
              reFetch={fetchFiles}
            />
          )}
        </>
      )}
      <Row>
        {folders.sort((a, b) => a.name.localeCompare(b.name)).map((folder) => (
          <Col key={folder.createdAt} xs={4} sm={2} md={3} lg={2} xl={1}>
            <FolderButton iconName={folder.iconName} title={folder.name} path={`/folder/${folder.id}`} />
          </Col>
        ))}
        {files.sort((a, b) => a.name.localeCompare(b.name)).map((file) => (
          <Col key={file.createdAt} md={12}>
            <FileButton isList iconName={file.iconName} title={file.name} path={`/file/${file.id}`} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
