import serviceUrl from "../config/baseUrls";
import baseApi from "./api";

const env = serviceUrl.API_PROD// process.env.NODE_ENV === 'PROD' ? serviceUrl.API_LOCAL : serviceUrl.API_LOCAL
const api = baseApi(env)

export const fileService = {
    list: () => {
        return api.get('/files-root')
    },
    open: ({fileId}) => {
        return api.get(`/files/open/${fileId}`)
    },
    openStream: ({fileId}) => {
        return `${env}/files/open-stream/${fileId}`
    },
    create: ({formData, updateProgress}) => {
        return api.post('/files', formData, {
            onUploadProgress: (event) => {
                let progress = Math.round(
                    (event.loaded * 100) / event.total
                );
                updateProgress(progress)
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    },
    delete: ({fileId}) => {
        return api.delete(`/files/${fileId}`)
    },
}

export const fileIcons = [
    {
        value: 'default',
        name: 'Selecione uma opção'
    },
    {
        value: 'diagnostico',
        name: 'Diagnostico'
    },
    {
        value: 'projeto',
        name: 'Projeto'
    },
    {
        value: 'estrategia',
        name: 'Estratégia'
    },
    {
        value: 'organizacao',
        name: 'Organizacional'
    },
    {
        value: 'matriz',
        name: 'Matriz'
    },
    {
        value: 'protocolo',
        name: 'Protocolo'
    },
    {
        value: 'sociedade',
        name: 'Sociedade'
    },
]