import FolderIcon from "../../assets/icons/folder.svg"
import {Title, File} from "./style"

export const FolderButton = ({iconName = null, title, path}) => {
    const titleParser = title.length >= 20
    ? `${title.substring(0, 20)}...`
    : title

    return (
        <File to={path} title={title}>
            <img src={FolderIcon} alt="file"/>
            <Title>{titleParser}</Title>
        </File>
    )
}