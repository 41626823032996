import {Button, Col, Container, Row, Form, Alert} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {userService} from "../../../../../services/userService";

export const CreateUserForm = () => {
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const [formError, setFormError] = useState('')

    function handleRegisterUser (event) {
        event.preventDefault()

        if(!name || !email){
            setFormError('Preencha todos os campos')
            return
        }

        userService
            .create({name, email, isAdmin})
            .then((response) => {
                alert('Usuário cadastrado')
                navigate('/users')
            }).catch(error => {
                const { message } = error.response.data
                setFormError(message)
            })
            .finally(() => {
            })
    }
    return (
        <Container>
            <Row className="py-4">
                <Col>
                    <h1>Cadastrar novo usuário</h1>
                    <Link to={"/users"} className={"btn btn-secondary"}>Voltar</Link>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Alert variant="warning">
                        <p>
                            Novos usuários tem por padrão a senha <code>123456789</code>.
                            O mesmo deve ser orientado a alterar a senha assim que acessar o sistema.
                        </p>
                    </Alert>

                    {formError && <Alert variant={'danger'}>{formError}</Alert>}
                    <Form onSubmit={handleRegisterUser}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="is_admin">
                            <Form.Check type="checkbox" label="Este usuário é um administrador?" checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Cadastrar
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}