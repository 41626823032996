import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FolderButton } from "../../../components/FolderButton";
import { FileButton } from "../../../components/FileButton";
import { useEffect, useState } from "react";
import { folderService } from "../../../services/folderService";
import { CreateFolderForm } from "./Forms/CreateFolder";
import { CreateFileForm } from "./Forms/CreateFile";
import { userIsAdmin } from "../../../services/auth";
import { Icon, IconWidget } from "../../../components/Icons";
import {RouteBackButton} from "../../../components/RouteBackButton";

export const SubFolders = () => {
  const navigate = useNavigate();
  let { folderId } = useParams();
  const [folder, setFolder] = useState({});
  const [files, setFiles] = useState([]);
  const [subFolders, setSubFolders] = useState([]);
  const [openFolderForm, setOpenFolderForm] = useState(false);
  const [openFileForm, setOpenFileForm] = useState(false);

  function handleCloseForm() {
    setOpenFolderForm(false);
    setOpenFileForm(false);
  }

  function deleteFolder() {
    if (window.confirm("Tem certeza que deseja deletar esta pasta?") !== true) {
      return;
    }
    folderService.delete({ folderId }).then(() => {
      alert("Pasta deletada");
      navigate("/");
    });
  }

  function fetchFolders() {
    folderService.open({ folderId }).then((response) => {
      setFolder(response.data);
      const { files: filesFetch, subFolders: subFolderFetch } = response.data;
      setFiles(filesFetch);
      setSubFolders(subFolderFetch);
    });
  }

  useEffect(fetchFolders, [folderId]);

  return (
    <Container>
        <>
          <Row className="py-4">
            <Col>
              <h1>{folder.name}</h1>
              <RouteBackButton/>{" "}
              {userIsAdmin() &&(
                  <>
                    <Button
                        variant="danger"
                        onClick={deleteFolder}
                        title="Deletar Pasta"
                    >
                      <IconWidget icon={Icon.DeleteWhite} alt="delete folder icon"/>
                    </Button>{" "}

                    {!openFolderForm && !openFileForm && (
                        <>
                          <Button
                              variant="secondary"
                              onClick={() => setOpenFolderForm(true)}
                          >
                            <IconWidget
                                icon={Icon.AddFolderWhite}
                                alt="add folder icon"
                            />
                            Nova Pasta
                          </Button>{" "}
                          <Button
                              variant="secondary"
                              onClick={() => setOpenFileForm(true)}
                          >
                            <IconWidget icon={Icon.UploadWhite} alt="add file icon" />
                            Novo Arquivo
                          </Button>
                        </>
                    )}
                  </>
              )}
            </Col>
          </Row>
          {openFolderForm && (
            <CreateFolderForm
              folderId={folderId}
              closeForm={handleCloseForm}
              reFetch={fetchFolders}
            />
          )}
          {openFileForm && (
            <CreateFileForm
              folderId={folderId}
              closeForm={handleCloseForm}
              reFetch={fetchFolders}
            />
          )}
        </>

      <Row>
        {subFolders.map((folder) => (
          <Col key={folder.createdAt} xs={4} sm={2} md={3} lg={2} xl={1}>
            <FolderButton iconName={folder.iconName} title={folder.name} path={`/folder/${folder.id}`} />
          </Col>
        ))}
        {files.map((file) => (
          <Col key={file.createdAt} xs={4} sm={2} md={3} lg={2} xl={1}>
            <FileButton iconName={file.iconName} title={file.name} path={`/file/${file.id}`} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
