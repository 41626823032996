import styled from 'styled-components'

export const BoxContent = styled.div`
    padding: 30px 15px;
    text-align: center;
`

export const BoxForm = styled.div`
  padding: 40px 20px;
  border-radius: 8px;
  background-color: #F1F1F1;
  border-top: solid 4px #2c2c2c;
`

export const AppVersion = styled.small`
  color: #9E9FA4;
`