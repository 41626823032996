import {useState} from "react";
import {Button, Col, Container, Row, Form, Alert} from "react-bootstrap";
import {userService} from "../../../../../services/userService";

export const UpdatePasswordUserForm = () => {
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [formError, setFormError] = useState('')

    function handleUpdatePasswordUser (event) {
        event.preventDefault()
        if(!password || !newPassword || password !== newPassword){
            setFormError('Preencha todos os campos corretamente')
            return
        }

        userService
            .updatePassword({password, newPassword})
            .then((response) => {
                alert('Senha atualizada')
                setPassword('')
                setNewPassword('')
            }).catch(error => {
                const { message } = error.response.data
                setFormError(message)
            })
            .finally(() => {

            })
    }

    return (
        <Container>
            <Row className="py-4">
                <Col>
                    <h1>Atualizar Senha</h1>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    {formError && <Alert variant={'danger'}>{formError}</Alert>}
                    <Form onSubmit={handleUpdatePasswordUser}>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Senha (só informe caso desejar atualizar)</Form.Label>
                            <Form.Control type="password" required min={9} max={16} value={password} onChange={e => setPassword(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newPassword">
                            <Form.Label>Senha (só informe caso desejar atualizar)</Form.Label>
                            <Form.Control type="password" required min={9} max={16} value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Atualizar Senha
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}