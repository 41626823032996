import serviceUrl from "../config/baseUrls";
import baseApi from "./api";

const env = serviceUrl.API_LOCAL//process.env.NODE_ENV === 'PROD' ? serviceUrl.API_LOCAL : serviceUrl.API_LOCAL
const api = baseApi(env)

export const accountService = {
    login: ({email, password}) => {
        return api.post('/auth/login', {email, password})
    }
}

