import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon, IconWidget } from "../../components/Icons";
import { Logo } from "../../components/Logo";
import { getUserName, logoutToken, userIsAdmin } from "../../services/auth";

export const MainMenu = () => {
  const navigate = useNavigate();
  return (
    <Navbar bg="dark" variant="dark" expand="md">
      <Container>
        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            <Link to={"/"} className={"nav-link"}>
              <IconWidget icon={Icon.FileBox} alt="file box icon" />
              Documentos
            </Link>
            {userIsAdmin() && (
              <Link to={"/users"} className={"nav-link"}>
                <IconWidget icon={Icon.Users} alt="users icon" />
                Usuários
              </Link>
            )}
          </Nav>
          <Nav>
            <Nav.Link href="/users/update/password">
              <IconWidget icon={Icon.Settings} alt="settings icon" />
              {getUserName()}
            </Nav.Link>
            <Button
              variant="outline-dark"
              onClick={() => {
                logoutToken();
                navigate("/login");
              }}
              style={{
                color: "#DC3545",
                fontWeight: "bold",
              }}
            >
              <IconWidget icon={Icon.LogoutRed} alt="log out icon" />
              Sair
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
