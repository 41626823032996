import serviceUrl from "../config/baseUrls";
import baseApi from "./api";

const env = serviceUrl.API_LOCAL// process.env.NODE_ENV === 'PROD' ? serviceUrl.API_LOCAL : serviceUrl.API_LOCAL
const api = baseApi(env)

export const folderService = {
    list: () => {
        return api.get('/folders')
    },
    open: ({folderId}) => {
        return api.get(`/folders/${folderId}`)
    },
    create: ({name, attachedToId = undefined}) => {
        return api.post('/folders', {name, attachedToId})
    },
    update: ({name, attachedToId = null}) => {
        return api.patch('/folders', {name, attachedToId})
    },
    delete: ({folderId}) => {
        return api.delete(`/folders/${folderId}`)
    },
}
