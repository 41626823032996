/* eslint-disable no-self-compare */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { Icon, IconWidget } from "./components/Icons";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <>
      {showReload && (
        <ToastContainer
          className="p-3"
          position="bottom-end"
          style={{ zIndex: 99999 }}
        >
          <Toast>
            <Toast.Header closeButton={false}>
              <IconWidget icon={Icon.Edit} alt="add folder icon" />
              <strong className="me-auto">PSA Library</strong>
              <small>Nova Atualização</small>
            </Toast.Header>
            <Toast.Body>
              <p>Adicionamos algumas melhorias.</p>
              <Button onClick={reloadPage} variant="success">
                Atualizar Página
              </Button>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
};

export default ServiceWorkerWrapper;
