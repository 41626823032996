import { Navigate } from 'react-router-dom'
import {isAuthenticated, userIsAdmin, userIsEnabled} from "../../services/auth";

export const ProtectedRoute = ({component, requireAdmin = false}) => {
    if(!isAuthenticated()){
        return <Navigate to="/login" replace />;
    }

    if(!userIsEnabled()){
        return <Navigate to="/login" replace />;
    }

    if(requireAdmin && !userIsAdmin()){
        return <Navigate to="/login" replace />;
    }
    return component
}