import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { userService } from "../../../services/userService";
import { Icon, IconWidget } from "../../../components/Icons";

export const Users = () => {
  const [users, setUsers] = useState([]);

  function fetchUsers() {
    userService.list().then((response) => {
      setUsers(response.data);
    });
  }

  useEffect(fetchUsers, []);

  return (
    <Container>
      <Row className="py-4">
        <Col>
          <h1>Lista de Usuários</h1>
          <Link to={"/users/create"} className={"btn btn-secondary"}>
            <IconWidget icon={Icon.AddUserWhite} alt="add user icon" />
            Novo Usuário
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th width={150}>Papel</th>
                <th width={60}>Status</th>
                <th width={60}>Ação</th>
              </tr>
            </thead>
            <tbody>
              {users.map(({ name, email, isAdmin, isEnabled, id }) => (
                <tr key={name}>
                  <td>{name}</td>
                  <td>{email}</td>
                  <td>{isAdmin ? "Administrador" : "Usuário"}</td>
                  <td>{isEnabled ? "Ativo" : "Inativo"}</td>
                  <td>
                    <Link
                      to={`/users/update/${id}`}
                      className={"btn btn-primary btn-sm"}
                    >
                      Editar
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
