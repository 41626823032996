import {useState} from "react";
import {Alert, Button, Card, CloseButton, Col, Form, ProgressBar, Row, Spinner} from "react-bootstrap";
import {fileService, fileIcons} from "../../../../../services/fileService";



export const CreateFileForm = ({ closeForm, reFetch }) => {
    const [name, setName] = useState('')
    const [file, setFile] = useState(null)
    const [iconName, setIconName] = useState('default')

    const [formError, setFormError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    

    function handleRegisterFolder(event) {
        event.preventDefault()
        if(!name || !file){
            setFormError('Preencha todos os campos')
            return
        }
        setIsLoading(true)
        setProgress(0)
        let formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('iconName', iconName);

        fileService
            .create({formData, updateProgress: setProgress})
            .then((response) => {
                reFetch()
            })
            .catch((error) => {
                const { message } = error.response.data
                setFormError(message)
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Row>
            <Col md={6}>
                <Card>
                    <Card.Body>
                        <Card.Title className={'d-flex justify-content-between'}>
                            Cadastrar Arquivo
                            <CloseButton aria-label="Hide" onClick={closeForm} />
                        </Card.Title>
                        <Card.Text>
                            {formError && <Alert variant="danger">{formError}</Alert>}
                            <Form onSubmit={handleRegisterFolder}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="iconName">
                                    <Form.Label>Icone</Form.Label>
                                    <Form.Select aria-label="Selecione um icone" onChange={e => setIconName(e.target.value)}>
                                        {fileIcons.map(({value, name}) => <option key={value+name} value={value}>{name}</option>)}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="file">
                                    <Form.Label>Arquivo (pdf)</Form.Label>
                                    <Form.Control type="file" onChange={e => setFile(e.target.files[0])}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="progress">
                                    <ProgressBar animated now={progress} label={`${progress}%`} className={'mb-2'}/>
                                </Form.Group>
                                <Button variant="primary" type="submit" disabled={isLoading}>
                                    { isLoading ? <Spinner animation={'border'}/> : 'Cadastrar'}
                                </Button>
                            </Form>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}