import axios from "axios";
import {getToken, logoutToken} from "./auth";

const baseApi = (baseUrl) => {
  const api = axios.create({
    baseURL: "https://be-library0.profittoagro.com",
  });

  api.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
    }
    return config;
  });

  api.interceptors.response.use(undefined, err => {
    const error = err.response;
    if (error.status === 401 ) {
      logoutToken()
      window.location.href = "/login"
    }
  });

  return api;
};

export default baseApi;
