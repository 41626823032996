import {useState} from "react";
import {folderService} from "../../../../../services/folderService";
import {Alert, Button, Card, CloseButton, Col, Form, Row, Spinner} from "react-bootstrap";

export const CreateFolderForm = ({ closeForm, reFetch }) => {
    const [folderName, setFolderName] = useState("");
    const [formError, setFormError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
  
    function handleRegisterFolder(event) {
      event.preventDefault();
      if (!folderName) {
        setFormError("Preencha todos os campos");
        return;
      }
      setIsLoading(true);
    
      folderService
        .create({ name: folderName, iconName: 'default' })
        .then((response) => {
          reFetch();
        })
        .catch((error) => {
          const { message } = error.response.data;
          setFormError(message);
        })
        .finally(() => setIsLoading(false));
    }
  
    return (
      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title className={"d-flex justify-content-between"}>
                Cadastrar Pasta
                <CloseButton aria-label="Hide" onClick={closeForm} />
              </Card.Title>
              <Card.Text>
                {formError && <Alert variant="danger">formError</Alert>}
                <Form onSubmit={handleRegisterFolder}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      value={folderName}
                      onChange={(e) => setFolderName(e.target.value)}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? <Spinner animation={"border"} /> : "Cadastrar"}
                  </Button>
                </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };