import {useEffect, useState} from "react";
import {Button, Col, Container, Row, Form, Alert} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {userService} from "../../../../../services/userService";

export const UpdateUserForm = () => {
    const { userId } = useParams()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const [isEnabled, setIsEnabled] = useState(true)
    const [formError, setFormError] = useState('')

    function handleUpdateUser (event) {
        event.preventDefault()
        if(!name){
            setFormError('Preencha todos os campos')
            return
        }

        const form = {
            name,
            isAdmin,
            isEnabled
        }

        if(password){
            Object.assign(form, {...form, password})
        }
        userService
            .update(userId,form)
            .then((response) => {
                alert('Usuário atualizado')
                navigate('/users')
            }).catch(error => {
                const { message } = error.response.data
                setFormError(message)
            })
            .finally(() => {

            })
    }


    function fetchShowUser(){
        userService
            .show({userId})
            .then((response) => {
                const {name, email, isAdmin, isEnabled } = response.data
                setEmail(email)
                setName(name)
                setIsAdmin(isAdmin)
                setIsEnabled(isEnabled)
            })
    }

    useEffect(fetchShowUser, [userId])
    return (
        <Container>
            <Row className="py-4">
                <Col>
                    <h1>Atualizar {name}</h1>
                    <Link to={"/users"} className={"btn btn-secondary"}>Voltar</Link>
                    {/*{' '}*/}
                    {/*<Button variant={'danger'} onClick={() => {*/}
                    {/*}}>Deletar</Button>*/}
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    {formError && <Alert variant={'danger'}>{formError}</Alert>}
                    <Form onSubmit={handleUpdateUser}>
                        <Form.Group className="mb-3" controlId="nameLastName">
                            <Form.Label>Nome e Sobrenome</Form.Label>
                            <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="emailAddress">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} disabled={true}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Senha (só informe caso desejar atualizar)</Form.Label>
                            <Form.Control type="password" min={9} max={16} value={password} onChange={e => setPassword(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="is_admin">
                            <Form.Check type="checkbox" label="Este usuário é um administrador?" checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="is_enabled">
                            <Form.Check type="checkbox" label="Este usuário está ativo?" checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Atualizar
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}